
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.employee-replacement {
  position: relative;
  padding: 14px 24px;
  border-radius: 12px;
  border: 1px solid #E8E8EE;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include for-size(phone-portrait-down) {
    padding: 7px 12px;
  }
  &__element {
    margin: 10px 0;
    @include for-size(phone-portrait-down) {
      margin: 5px 0;
    }
  }
}
