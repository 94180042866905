
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.employee-replacement-address-list {
  &__search-input,
  &__wrapper {
    margin-bottom: math.div($gap, 2);
  }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}

.employee-replacement-address-list-item {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  transition: background $base-animation;
  border-radius: math.div($base-border-radius, 2);
  position: relative;

  &__column {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;

    span {
      color: $color-black-op-60;
    }

    > * {
      display: block;
    }
  }

  &:not(:last-child):before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 10px);
    height: 1px;
    background: $color-black-op-25;
  }

  &:hover {
    background: $color-black-op-15;
  }

}

